<template>
    <div>
        <el-form ref="queryForm" :model="queryModel" inline>
        <el-form-item label="选择路线" prop="routeId">
            <el-select
                v-model="queryModel.routeId"
                size="mini"
                filterable
                remote
                placeholder="请选择" 
                clearable             
                :remote-method="queryRouteList"
            >
                <el-option
                v-for="route in innerRouteList"
                :key="route.id"
                :label="route.name"
                :value="route.id"
                ></el-option>
            </el-select>
        </el-form-item>
       <el-form-item label="选择学年度" prop="periodId">
        <el-select
                v-model="queryModel.periodId"
                size="mini"
                filterable
                placeholder="选择学年度"
            >
            <el-option
            v-for="period in periodList"
            :key="period.id"
            :label="period.name"
            :value="period.id"
            ></el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="服务学校" prop="schoolId">
            <el-select-tree
            :props="schoolProps"
            size="mini"
            :options="schoolList"
            v-model="queryModel.schoolId"
            height="200"
            ></el-select-tree>
        </el-form-item>
        <el-form-item label="学生姓名" prop="name">
           <el-input v-model="queryModel.name"
            size="mini"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
            >查询</el-button>&nbsp;
            <el-button
            type="info"
            size="mini"
            style="margin-left: 8px"
            @click="handleReset('queryForm')"
            >重置</el-button>&nbsp;
        </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="routeName" label="线路名称" width="100"></el-table-column>
            <el-table-column prop="stationName" label="上车站点" width="100"></el-table-column>
            <el-table-column prop="periodName" label="学年度" width="100"></el-table-column>
            <el-table-column prop="name" label="学生姓名" width="100"></el-table-column>
            <el-table-column prop="sex" label="性别" width="100"></el-table-column>
            <el-table-column prop="schoolName" label="学校" width="100"></el-table-column>
            <el-table-column prop="className" label="班级" width="100"></el-table-column>
            <el-table-column prop="phone" label="手机号码" width="120"></el-table-column>
            <el-table-column prop="payName" label="缴费方式" width="120"></el-table-column>
            <el-table-column prop="payDate" label="缴费日期" width="120"></el-table-column>
        </el-table>
        <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
    </div>
</template>
<script>
import scheduleApi from "@/api/bus/schedule";
import companyInfoApi from "@/api/base/companyInfo";
import routeApi from "@/api/bus/route";
import SelectTree from "@/components/SelectTree";

export default {
    props:{
        width: {
            type: String,
            default: '200px'
        },
        size: {
            type: String,
            default: 'medium'
        },
        value: {
            type: Array,
            default: () => {
                return [];
            }
        },
        routeList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        schoolList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        periodList: {
            type: Array,
            default: () => {
                return [];
            }
        },
        schoolId: String,
        routeId: String,
        periodId: String
    },
    components:{
        "el-select-tree": SelectTree
    },
    // watch: {
    //     schoolId(newVal,oldVal){
    //         this.queryModel.schoolId = newVal;
    //     },
    //     routeId(newVal,oldVal){
    //         this.queryModel.routeId = newVal;
    //     },
    //     periodId(newVal,oldVal){
    //         this.queryModel.periodId = newVal;
    //     }
    // },
    data() {
        return {
            queryModel: {
                routeId: "",
                schoolId: "",
                periodId: "",
                name: ""
            },
            pageIndex: 1,
            pageSize: 20,
            totalPages: 0,
            totalElements: 0,
            pageSizeList: [20, 30, 50],
            tableData: [],
            loading: false, 
            schoolProps: {
                // 配置项（必选）
                value: "id",
                label: "name",
                children: "children"
            },
            innerRouteList:[]
        }
    },
    methods:{
        handleReset(name) {
            this.$refs[name].resetFields();
            this.queryModel.schoolId = null;
        },
        queryRouteList(keywords) {
            var formData = new FormData();
            formData.append("routeName", keywords);

            formData.append("pageIndex", 1);
            formData.append("pageSize", 20);

            routeApi.pageList(formData).then(response => {
                var jsonData = response.data;

                if (jsonData.result) {
                this.innerRouteList = jsonData.data.data;
                }
            });
        },
        changePage(pageIndex) {
            var formData = new FormData();

            if(this.queryModel.routeId!=null){
                formData.append("routeId", this.queryModel.routeId);
            }

            if(this.queryModel.schoolId!=null){
                formData.append("schoolId", this.queryModel.schoolId);
            }

            if(this.queryModel.periodId!=null){
                formData.append("periodId", this.queryModel.periodId);
            }

            if(this.queryModel.name!=null){
                formData.append("name", this.queryModel.name);
            }
            
            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);

            this.loading = true;

            scheduleApi.queryRegStudentList(formData).then(response => {
                this.loading = false;

                console.log(response);
                var jsonData = response.data;

                if (jsonData.result) {
                    var page = jsonData.data;

                    this.tableData = page.data;
                    this.totalPages = page.totalPages;
                    this.totalElements = page.recordsTotal;
                }
                else{
                    this.$message.error("系统错误" + jsonData.message);
                }
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;

            this.$nextTick(() => {
                this.changePage(this.pageIndex);
            });
        },
        handleSelectionChange(val) {
            this.$emit("input", val);
        }
    },
    mounted() {
        this.queryModel.schoolId = this.schoolId;
        this.queryModel.routeId = this.routeId;
        this.queryModel.periodId = this.periodId;

        this.routeList.forEach(item=>{
            this.innerRouteList.push(item);
        });
        
        this.changePage(1);
    }
}
</script>
<style>

</style>