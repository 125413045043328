/*
    排班管理api
*/
import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/vehicleSchedule/pageList", formData);
}

function edit(id){
    return request.get(constant.serverUrl + "/bus/vehicleSchedule/edit/" + id);
}

function create(){
  return request.get(constant.serverUrl + "/bus/vehicleSchedule/create");
}

function add(formModel){
    return request.post(constant.serverUrl + "/bus/vehicleSchedule/add", formModel,{
        headers: {
          "Content-Type": "application/json"
        }
    });
}

function update(formModel){  
    return request.post(constant.serverUrl + "/bus/vehicleSchedule/update", formModel,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

function remove(id){
  return request.post(constant.serverUrl + "/bus/vehicleSchedule/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/bus/vehicleSchedule/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function listOfVehicle(){
  return request.get(constant.serverUrl + "/bus/vehicleSchedule/listOfVehicle");
}

function listOfPeriod(){
  return request.get(constant.serverUrl + "/bus/vehicleSchedule/listOfPeriod");
}

function queryRegStudentList(formModel){
  return request.post(constant.serverUrl + "/bus/vehicleSchedule/queryRegStudentList", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function scheduleList(){
  return request.post(constant.serverUrl + "/bus/vehicleSchedule/scheduleList");
}

function routeScheduleList(routeId){
  return request.post(constant.serverUrl + "/bus/vehicleSchedule/routeScheduleList/"+routeId);
}

function importPersonToDevice(data) {
  return request.post(constant.serverUrl + "/bus/vehicleSchedule/importPersonToDevice",data,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
    pageList,add,update,edit,remove,batchRemove,create,listOfVehicle,
    listOfPeriod,scheduleList,queryRegStudentList,importPersonToDevice,
    routeScheduleList
}