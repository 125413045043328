<template>
  <el-select
    :size="size"
    :style="{width:width}"
    filterable
    remote
    clearable
    :value="value" @input="$emit('input', $event)"
    placeholder="请输入姓名或拼音首字母查询人员"
    :remote-method="queryPersonList"
  >
    <el-option
        v-for="person in personList"
        :key="person.id"
        :label="person.id + '-' + person.name + '-' + person.phone"
        :value="person.id"
    ></el-option>
</el-select>
</template>
<script>
import personInfoApi from "@/api/base/personInfo";
export default {
    props:{
        value: {
            type: Number,
            default: 0
        },
        width: {
            type: String,
            default: '200px'
        },
        size: {
            type: String,
            default: 'medium'
        },
        popedom: {
            type: String,
            default: null
        }
    },
    watch:{
        value(newVal,oldVal) {
            if(oldVal==null && newVal!=null){
                console.log("选人控件初始化查询:" + newVal);
                this.queryPersonList(null, newVal);
            }
        }
    },
    data() {
        return {
            personList: []
        }
    },
    methods:{
        queryPersonList(personName,personId) {
            var formData = new FormData();

            if(personId!=null){
                formData.append("id", personId);
            }

            if(personName!=null && personName.length>0){
                formData.append("name", personName);
            }

            if(this.popedom!=null){
                formData.append("popedom", this.popedom);
            }

            formData.append("limit", 20);

            personInfoApi.topList(formData).then(response => {
                var jsonData = response.data;

                if (jsonData.result) {
                    this.personList = jsonData.data;
                }
            });
        }
    },
    mounted() {
    }
}
</script>
<style>

</style>