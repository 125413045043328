
<style scoped>
.user-panel {
  margin: 10px auto;
}

.user-panel >>> .el-form-item__content{
  width: 400px !important;
}
</style>
<template>
  <div>
    <el-dialog
      :visible.sync="showDialog"
      :title="title"
      width="80%"
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      style="text-align:left;"
      @close="closeDialog"
    >
      <div class="user-panel" 
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      >
        <el-form ref="form" :inline="true" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
          <el-form-item label="班次编号" prop="no">
            <el-input v-model="formModel.no" placeholder="请输入班次自编号" style="width:350px"></el-input>
          </el-form-item>
          <el-form-item label="班次名称" prop="name">
            <el-input v-model="formModel.name" placeholder="请输入班次名称" style="width:350px"></el-input>
          </el-form-item>
          <el-form-item label="选择线路" prop="routeId">
            <el-select
                  style="width:350px"
                  v-model="formModel.routeId"
                  filterable
                  placeholder="请选择线路名称"
                >
              <el-option
                v-for="route in routeList"
                :key="route.id"
                :label="route.name"
                :value="route.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择校车" prop="vehicleId">
            <el-select
                  style="width:350px"
                  v-model="formModel.vehicleId"
                  filterable
                  placeholder="请输入车辆编号"
                >
                <el-option
                  v-for="vehicle in vehicleList"
                  :key="vehicle.id"
                  :label="vehicle.licensePlateNumber"
                  :value="vehicle.id"
                ></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="选择司机" prop="driverId">
            <el-select-person v-model="formModel.driverId" popedom="5" width="350px"></el-select-person>
          </el-form-item>
          <el-form-item label="选择照看员" prop="lifeguardId">
            <el-select-person v-model="formModel.lifeguardId" popedom="6" width="350px"></el-select-person>
          </el-form-item>
          <el-form-item label="选择交接员" prop="handoverId">
            <el-select-person v-model="formModel.handoverId" popedom="3" width="350px"></el-select-person>
          </el-form-item>
          <el-form-item label="每周排班" prop="weekdayList">
            <el-checkbox-group v-model="formModel.weekdayList" size="mini">
              <el-checkbox-button v-for="weekday in weekdays" :label="weekday.value" :key="weekday.value">{{weekday.label}}</el-checkbox-button>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="服务学校" prop="schoolId">
            <el-select-tree
              :props="schoolProps"
              size="medium"
              :options="schoolList"
              v-model="formModel.schoolId"
              height="200px"
              width="350px"
            ></el-select-tree>
          </el-form-item>
          <el-form-item label="选择学年度" prop="periodId">
            <el-select
                  style="width:350px"
                  v-model="formModel.periodId"
                  filterable
                  placeholder="选择学年度"
                >
              <el-option
                v-for="period in periodList"
                :key="period.id"
                :label="period.name"
                :value="period.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <div v-for="(row,index) in formModel.tripList" :key="index">
            <el-form-item label="午别" :prop="'tripList.' + index + '.classify'" :rules="ruleValidate['classify']">
              <el-select
                    style="width:350px"
                    v-model="row.classify"
                    filterable
                    placeholder="请选择"
                    disabled
                  >
                  <el-option :value="1" label="上午"></el-option>
                  <el-option :value="2" label="下午"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="起止时间" :prop="'tripList.' + index + '.timeRange'" :rules="ruleValidate['timeRange']">
              <el-time-picker
                v-model="row.timeRange"
                is-range
                arrow-control
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                value-format="HH:mm:ss"
                >
              </el-time-picker>
            </el-form-item>
          </div>
          <div style="display:flex;justify-content: space-between;">
            <h3>关联乘车学生信息</h3>
            <div>            
              <el-button type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="handleAddPassenger()">新增乘客</el-button>
              <el-button type="primary" size="mini" icon="el-icon-user-solid" @click="batchImport()">批量导入</el-button>
              <el-button type="primary" size="mini" icon="el-icon-user-solid" @click="batchExport()">批量导出</el-button>
              <el-button type="primary" size="mini" icon="el-icon-refresh" @click="importPersonToDevice()">同步乘客照片到设备</el-button>
            </div>
          </div>
          <el-table
            :data="formModel.passengers"
            width="100%"
            height="400"
            >
            <el-table-column type="index" label="序号" width="55"></el-table-column>
            <el-table-column prop="personId" label="人员编号" width="100"></el-table-column>
            <el-table-column prop="name" label="学生姓名" width="100"></el-table-column>
            <el-table-column prop="idCard" label="身份证号" width="180"></el-table-column>
            <el-table-column prop="phone" label="手机号码" width="120"></el-table-column>
            <el-table-column prop="className" label="所在班级"></el-table-column>
            <!-- <el-table-column prop="classTeacher" label="班主任" width="100"></el-table-column>
            <el-table-column prop="teacherPhone" label="班主任联系方式"></el-table-column> -->
            <el-table-column label="操作" fixed="right" width="100">
                <template slot-scope="{row}">
                    <el-button icon="el-icon-delete" :plain="true" @click="handleDelete(row)">删除</el-button>
                </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      width="75%"
      :visible.sync="showStudentDialog"    
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <el-select-reg-route-student-table 
      ref="selectRegRouteStudentTable"
      :route-list="routeList"
      :period-list="periodList"
      :school-list="schoolList"
      :route-id="formModel.routeId"
      :period-id="formModel.periodId"
      :school-id="formModel.schoolId"
      v-model="selectedStudent">
      </el-select-reg-route-student-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showStudentDialog=false">取 消</el-button>
        <el-button type="primary" @click="confirmSelectStudent">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="batchImportVisible"    
      :modal-append-to-body="true"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <!--批量导入乘客-->      
      <el-form label-width="150px" v-loading="xlsLoading">
        <el-form-item label="模板下载">
          <el-link
            href="http://rccs.oss-cn-hangzhou.aliyuncs.com/smart_school/vehicleInfo/2020/导入乘客模板.xls"
            type="primary"
            target="_blank"
          >下载模板</el-link>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            accept=".xls"
            :action="batchImportUrl"
            :data="importData"
            :headers="headers"
            name="uploadFile"
            :before-upload="handleBeforeUpload"
            :on-success="handleBatchImportSuccess"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xls文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="batchImportVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import SelectTree from "@/components/SelectTree";
import SelectPerson from "@/components/SelectPerson";
import SelectRegRouteStudentTable from "@/components/SelectRegRouteStudent/table";
import scheduleApi from "@/api/bus/schedule";
import companyInfoApi from "@/api/base/companyInfo";
import routeApi from "@/api/bus/route";
import personInfoApi from "@/api/base/personInfo";
import { getToken } from "@/utils/auth"; // get token from cookie

export default {
  props: ["businessKey", "title"],
  components:{
    "el-select-tree": SelectTree,
    "el-select-person": SelectPerson,
    "el-select-reg-route-student-table": SelectRegRouteStudentTable
  },
  data() {
    return {
      studentRule: {
        studentId: [
          { required: true, message: "学生不能为空", trigger: "blur" }
        ]
      },
      ruleValidate: {
        routeId: [
          { required: true, message: "路线不能为空", trigger: "blur" }
        ],
        vehicleId: [
          { required: true, message: "车辆不能为空", trigger: "blur" }
        ],
        periodId: [
          { required: true, message: "学期不能为空", trigger: "blur" }
        ],
        no: [
          { required: true, message: "班次编号不能为空", trigger: "blur" }
        ],
        name: [
          { required: true, message: "班次名称不能为空", trigger: "blur" }
        ],
        timeRange: [
          { required: true, message: "起止时间不能为空", trigger: "blur" }
        ],
        weekdayList: [
          { required: true, message: "排班不能为空", trigger: "blur" }
        ],
        driverId: [
          { required: true, message: "司机不能为空", trigger: "blur" }
        ],
        lifeguardId: [
          { required: true, message: "照看员不能为空", trigger: "blur" }
        ],
        handoverId: [
          { required: true, message: "交接员不能为空", trigger: "blur" }
        ],
        schoolId: [
          { required: true, message: "学校不能为空", trigger: "change" }
        ],
        classify: [
          { required: true, message: "接送类型不能为空", trigger: "change" }
        ]
      },
      showDialog: true,
      loading: false,
      loadingText: '加载中',
      submitting: false,
      formModel: {
        no: "",
        name: "",
        vehicleId: null,
        routeId: null,
        schoolId: null,
        periodId: null,
        driverId: null,
        lifeguardId: null,
        handoverId: null,
        tripList:[],
        weekdayList: [],
        passengers: [],
        removePassengers: []
      },
      routeList: [],
      vehicleList: [],
      schoolProps: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
      schoolList: [],
      periodList: [],
      weekdays:[
        {"label": "周一",value:"1"},
        {"label": "周二",value:"2"},
        {"label": "周三",value:"3"},
        {"label": "周四",value:"4"},
        {"label": "周五",value:"5"},
        {"label": "周六",value:"6"},
        {"label": "周日",value:"7"}
      ],
      showStudentDialog: false,
      selectedStudent: [],
      batchImportVisible: false,
      headers: {
        Authorization: getToken()
      },
      batchImportUrl: Constant.serverUrl + "/bus/vehicleSchedule/importPassengers",
      xlsLoading: false
    };
  },
  computed: {
    importData() {
      return {
        schoolId: this.formModel.schoolId,
        routeId: this.formModel.routeId,
        periodId : this.formModel.periodId
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.loadingText = "提交中";

          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return scheduleApi.add(self.formModel);
            } else {
              return scheduleApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;
            self.loading = false;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });
            }
          });
        }
      });
    },
    handleAddPassenger() {
      this.showStudentDialog = true;
      this.selectedStudent = [];

      // var selectTable = this.$refs["selectRegRouteStudentTable"];

      // if(selectTable!=null){
      //   selectTable.queryModel.periodId = this.formModel.periodId;
      //   selectTable.queryModel.schoolId = this.formModel.schoolId;
      //   selectTable.queryModel.routeId = this.formModel.routeId;
      // }
    },
    handleDelete(row) {
      this.$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.formModel.passengers.splice(this.formModel.passengers.indexOf(row),1);
          this.formModel.removePassengers.push(row);
        });
    },
    existSamePassenger(passenger) {
      var arr = this.formModel.passengers.filter(item=>{
        return item.personId == passenger.personId;
      });

      return arr.length!=0;
    },
    confirmSelectStudent() {
      this.selectedStudent.forEach((item)=>{
        var exist = this.existSamePassenger(item);

        if(!exist){
          this.formModel.passengers.push(item);
        }
        else{
          this.$message.warning( item.name + "已在乘客名单中!");
        }
      });
           
      this.showStudentDialog = false;
    },
    batchImport() {
      //批量导入
      this.xlsLoading = false;
      this.batchImportVisible = true;
    },
    batchExport() {
      //批量导出
      var BOM = "\uFEFF";

      var csv = [];
      var titles = ["序号","学生姓名","身份证号","联系电话","所在学校","所在班级","班主任","班主任联系方式"];

      csv.push(titles.join("\t,"));

      this.formModel.passengers.forEach((item)=>{
        var arr = [];

        arr.push(csv.length);
        arr.push(item.name);
        arr.push(item.idCard);
        arr.push(item.phone);
        arr.push(item.schoolName);
        arr.push(item.className);
        arr.push(item.classTeacher);
        arr.push(item.teacherPhone);

        csv.push(arr.join("\t,"));
      });

      var csvStr = csv.join("\n");

      var link = document.createElement("a");
      link.setAttribute("href","data:attachment/csv;charset=utf-8," + BOM + encodeURIComponent(csvStr));
      link.setAttribute("target","_blank");
      link.setAttribute("download","导出报表.csv");
      link.click();
    },
    handleBeforeUpload() {
      this.xlsLoading = true;      
    },
    handleBatchImportSuccess(response){
      //导入完毕
      if(response.result){
        //添加乘客
        response.data.forEach((item)=>{
          var exist = this.existSamePassenger(item);

          if(!exist){
            this.formModel.passengers.push(item);
          }
          else{            
            this.$message.warning( item.name + "已在乘客名单中!");
          }
        });

        if(response.message!=null && response.message.length>0){
            this.$message({
              showClose: true,
              dangerouslyUseHTMLString: true,
              message: response.message,
              duration: 30000
            });
        }

        this.batchImportVisible = false;
      }
      else{
         this.$message({
            showClose: true,
            dangerouslyUseHTMLString: true,
            message: response.message,
            duration: 30000
          });
      }
    },
    importPersonToDevice() {
      var idList = this.formModel.passengers.map(item=>item.personId);

      //加上司机和照看员
      idList.push(this.formModel.driverId);
      idList.push(this.formModel.lifeguardId);

      this.loading = true;

      scheduleApi.importPersonToDevice({
        vehicleId : this.formModel.vehicleId,
        idList: idList
      }).then(response=>{
        this.loading = false;

        var jsonData = response.data;

        if(jsonData.result){
          this.$message.success(jsonData.message + "");
        }
        else{
          this.$message.warning("导入失败!" + jsonData.message); 
        }
      });
    }
  },
  async mounted() {
    var self = this;

    this.loading = true;
    this.loadingText = "加载中";

    await routeApi.list().then((resp)=>{
      var jsonData = resp.data;
      
      if (jsonData.result) {
        this.routeList = jsonData.data;
      }
    });

    await scheduleApi.listOfVehicle().then((resp)=>{
      var jsonData = resp.data;
      
      if (jsonData.result) {
        this.vehicleList = jsonData.data;
      }
    });

    await companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;

      if (jsonData.result) {
        self.schoolList = jsonData.data;
      }
    });


    await scheduleApi.listOfPeriod().then((resp)=>{
      var jsonData = resp.data;
      
      if (jsonData.result) {
        this.periodList = jsonData.data;
      }
    });
   
    (function() {
      if (self.businessKey.length == 0) {
        return scheduleApi.create();
      } else {
        return scheduleApi.edit(self.businessKey);
      }
    })()
      .then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.$message.error(error + "");
      });
  }
};
</script>