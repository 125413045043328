import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/bus/route/pageList", formData);
}

function edit(id){
    return request.get(constant.serverUrl + "/bus/route/edit/" + id);
}

function create(){
  return request.get(constant.serverUrl + "/bus/route/create");
}

function add(formModel){
    return request.post(constant.serverUrl + "/bus/route/add", formModel,{
        headers: {
          "Content-Type": "application/json"
        }
    });
}

function update(formModel){  
    return request.post(constant.serverUrl + "/bus/route/update", formModel,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}

function remove(id){
  return request.post(constant.serverUrl + "/bus/route/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/bus/route/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function stationClassifyList(){
  return request.post(constant.serverUrl + "/bus/route/stationClassifyList");
}

function routeList(){
  return request.post(constant.serverUrl + "/bus/route/routeList");
}

function list(){
  return request.get(constant.serverUrl + "/bus/route/list");
}

function updateMapPath(formModel) {
  return request.post(constant.serverUrl + "/bus/route/updateMapPath", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function setCost(formData) {
    return request.post(constant.serverUrl + "/bus/route/setCost", formData);
}

export default {
    pageList,add,update,edit,remove,batchRemove,create,stationClassifyList,routeList,list,updateMapPath,setCost
}
